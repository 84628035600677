<template>
  <section>
    <div
    class="box h-full w-full"
    style="overflow:hidden;"
    >
      <div
      id="grafica"
      ref="elGrafica"
      style="height:450px;display: none;"
      class="w-full"
      />
    </div>
  </section>
</template>

<script>
import * as echarts from 'echarts'
import { computed, onMounted, reactive, ref, watch } from 'vue'
import InformesRipsService from '../services'
import currency from 'currency.js'
// import { messageWarning } from '../../../../../../libs/mensajes.js'
// import axios from 'axios'

import { useStore } from 'vuex'

const navegadorVentanaAncho = 1024
export default {
  name: 'GraficaPatologia',
  props: {
    filters: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const store = useStore()
    const _informesRipsService = new InformesRipsService()

    const loading = ref(false)
    const elGrafica = ref(null)
    let chartInstance = reactive({})
    const laData = ref([])
    const isDark = computed(() => {
      return store.getters['main/darkMode']
    })
    const getChartLabels = () => {
      return laData.value.map(a => a.descripcion.substring(0, 18) + '...')
    }
    const getData = () => {
      return laData.value.map(a => a.Valor)
    }
    const setLaData = (values) => {
      laData.value = values
    }
    const initChart = () => {
      chartInstance = echarts.init(elGrafica.value, isDark.value ? 'dark' : 'light')
      const option = {
        backgroundColor: isDark.value ? '#313A55' : '',
        title: {
          text: 'Consumo por Patología',
          top: 15,
          left: 15
        },
        toolbox: {
          top: 15,
          right: 15
          // feature: {
          //   myTool1: {
          //       show: true,
          //       right: 15,
          //       top: 10,
          //       title: 'Detallado',
          //       icon: 'image:///images/excel.png',
          //       onclick: () => {
          //         descargarExcel()
          //       }
          //   }
          // }
        },
        grid: {
          left: 20,
          right: 20,
          bottom: 15,
          top: 50,
          containLabel: true
        },
        tooltip: {
          formatter (a) {
            a.tooltip = laData.value[a.dataIndex].tooltip
            const row = a.tooltip
            if (window.innerWidth < navegadorVentanaAncho) {
              // row = row
              return `<div style="width:140px; white-space: pre-wrap;">${row}</div>`
            }
            return row
          }
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01],
          axisLabel: { // Configuraciones relacionadas para etiquetas de escala de eje de coordenadas.
            interval: 0,
            formatter (a) {
              return `${currency(a / 1000, { precision: 0, separator: '.' }).format()}.Mil`
            }
          }
        },
        yAxis: {
          type: 'category',
          data: getChartLabels(),
          position: 'left',
          axisLine: {
            onZero: true
          },
          axisLabel: {
            fontSize: 8,
            width: 100,
            overflow: 'truncate'
          }
        }
      }
      chartInstance.setOption(option)
      loadEvents()
    }

    watch(props.filters, (filter) => {
      if (filter.year !== '') {
        fetchResults(filter)
      }
    })

    // const descargarExcel = () => {
    //   if (props.filters.client) {
    //     const url = `${process.env.VUE_APP_MICROSERVICE_API_DASHBOARD}/execution-report/consumption-pathology-report`
    //     const res = axios.getUri({ url, params: props.filters })
    //     window.open(res, '_blank')
    //   } else {
    //     messageWarning('Por favor seleccione un cliente para realizar la busqueda del paciente')
    //   }
    // }

    const fetchResults = (filter) => {
      loading.value = true
      _informesRipsService.apiPatologia(filter).then(({ data }) => {
        setLaData(data.reverse())
        setChart()
      }).catch((err) => {
        console.log(err.message)
      }).finally(() => {
        loading.value = false
      })
    }
    const setChart = () => {
      const option = {
        xAxis: { show: true },
        yAxis: {
          show: true,
          type: 'category',
          data: getChartLabels(),
          label: {
            fontSize: 6
          }
        },
        series: [
          {
            data: getData(),
            type: 'bar',
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            },
            label: {
              show: true,
              position: 'right',
              fontSize: 8,
              formatter (d) {
                return `${currency(d.value / 1000, { precision: 0, separator: '.' }).format()}.Mil`
              }
            }
          }
        ]
      }

      if (window.innerWidth < navegadorVentanaAncho) {
        option.yAxis.show = false
        option.yAxis.data = []
        option.xAxis.show = false
      }

      chartInstance.setOption(option)
    }

    const loadEvents = () => {
      window.addEventListener('resize', (evt) => {
        setChart()
        chartInstance.resize()
      })
    }

    onMounted(() => {
      initChart()
    })
    return {
      elGrafica,
      loading,
      _informesRipsService
    }
  }
}
</script>

<style>

</style>
