import http from '../../../../../libs/http'

export default class InformesRipsService {
    cargar (payload) {
      return http.post(`${process.env.VUE_APP_MICROSERVICE_API_CARGE_RIPS}/upload`, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
          loading: true
        }
      })
    }

    apiPatologia (payload) {
      return http.get(`${process.env.VUE_APP_MICROSERVICE_API_CARGE_RIPS}/dashboard/top-diagnoticos`, { params: { ...payload } }, {
        headers: {
          loading: true
        }
      })
    }

    apiResumeTotales (payload) {
      return http.get(`${process.env.VUE_APP_MICROSERVICE_API_CARGE_RIPS}/dashboard/total-rips`, { params: { ...payload } }, {
        headers: {
          loading: true
        }
      })
    }

    apiTopPacientes (payload) {
      return http.get(`${process.env.VUE_APP_MICROSERVICE_API_CARGE_RIPS}/dashboard/top-pacientes`, { params: { ...payload } }, {
        headers: {
          loading: true
        }
      })
    }

    apiPaginate (payload) {
      return http.get(`${process.env.VUE_APP_MICROSERVICE_API_CARGE_RIPS}/listar/listar-rips`, { params: { ...payload } }, {
        headers: {
          loading: true
        }
      })
    }

    onRemove (id) {
      return http.delete(`${process.env.VUE_APP_MICROSERVICE_API_CARGE_RIPS}/listar/delete/${id}`, {
        headers: {
          loading: true
        }
      })
    }

    download (id) {
      return http.get(`${process.env.VUE_APP_MICROSERVICE_API_CARGE_RIPS}/listar/download/${id}`, {
        headers: {
          loading: true
        }
      })
    }
}
